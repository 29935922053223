.board-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 95%;
}

.board-member img {
    width: 100%;
    height: 80%;
    border-radius:0.5rem;
    object-fit: cover;
}

.board-member-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 20%;
}

.board-member-name {
    font-size: 1.5rem;
    font-weight: 600;
}

.board-member-role {
    opacity: 0.8;
    font-size: 1rem;
    font-weight: 500;
}

.board-member-social {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;

    & a {
        text-decoration: none;
    }
}

.board-member-icon {
    color: #000;
    transition: all 0.1s ease-in-out;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
    }
}

.board-member-show-copied {
    position:fixed;
    right: 1rem;
    bottom: 1rem;
    background-color: var(--primary-color);
    padding: 1rem;
    border-radius: 0.5rem;
    color: #ffffff
}


@media (width < 550px){
    .board-member-name {
        font-size: 1rem;
    }
    .board-member-role {
        font-size: 0.8rem;
    }
    .board-member img {
        height: 75%;
    }
    .board-member-info {
        height: 25%;
    }
    .board-member-icon {
        font-size: 1rem;
    }
}
