.course-card-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 90%;
    width: 100%;
}

.course-card-image {
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 0.5rem;
}

.course-card-left {
    width: 30rem;
    height: 100%;
}

.course-card-time-label {
    position: absolute;
    top: 0.5rem;
    left:0.5rem;
}

.course-card-labels {
    padding-top: 0.2rem;
    display: flex;
    gap: 0.3rem;
}

.course-card-right {
    height: 100%;
    width: fill;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-card-content {
    height: 95%;
    width: fill;
    padding-left: 1rem;
    padding-right:1rem ;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course-card-title {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
}

.course-card-description {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.course-card-text {
    font-size: 1rem;
}

.course-card-detail-section {
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    justify-content:left ;
    gap:0.3rem
}

.course-card-icon {
    font-size: 1.3rem;
    width: 1.5rem;
    align-items: center;
}

.course-card-see-details-button {
    width: 10rem;
    margin-right: 3rem;
}


@media (width < 1200px) {
    .course-card-image {
        width: 18rem;
    }

    .course-card-title {
        font-size: 1.2rem;
    }

    .course-card-description {
        font-size: 0.8rem;
    }

    .course-card-text {
        font-size: 0.8rem;
    }

    .course-card-icon {
        font-size: 1rem;
        width: 1.2rem;
    }

    .course-card-detail-text {
        font-size: 0.8rem;
    }

    .course-card-see-details-button {
        font-size: 0.8rem;
        margin: 0;
    }
}

@media(width<820px) {
    .course-card-image {
        width: 12rem;
    }

    .course-card-title {
        font-size: 1rem;
    }

    .course-card-description {
        font-size: 0.6rem;
    }

    .course-card-text {
        font-size: 0.6rem;
    }

    .course-card-icon {
        font-size: 0.8rem;
        width: 1.2rem;
    }

    .course-card-detail-text {
        font-size: 0.6rem;
    }

    .course-card-see-details-button {
        font-size: 0.6rem;
    }
}

@media (width<650px) {
    .course-card-container {
        flex-direction: column;
        align-items: start;
        height:30rem;
    }

    .course-card-left {
        width:100%;
        height:40%;
    }

    .course-card-image {
        width: 100%;
        height: 100%;
    }

    .course-card-title {
        font-size: 1.2rem;
    }

    .course-card-description {
        font-size: 0.8rem;
    }

    .course-card-text {
        font-size: 0.8rem;
    }

    .course-card-icon {
        font-size: 1rem;
        width: 1.2rem;
    }

    .course-card-detail-text {
        font-size: 0.8rem;
    }

    .course-card-see-details-button {
        font-size: 0.8rem;
    }

    .course-card-title {
        font-size: 1.2rem;
    }

    .course-card-description {
        font-size: 0.8rem;
    }

    .course-card-text {
        font-size: 0.8rem;
    }

    .course-card-icon {
        font-size: 1rem;
        width: 1.2rem;
    }

    .course-card-detail-text {
        font-size: 0.8rem;
    }

    .course-card-see-details-button {
        font-size: 0.8rem;
        margin-top: 0.5rem;
        width: 97%;
    }

    .course-card-right {
        flex-direction: column;
    }

    .course-card-content {
        width: 97%;
    }
}

@media (width < 550px) {
    .course-card-title {
        font-size: 1rem;
    }

    .course-card-description {
        font-size: 0.6rem;
    }

    .course-card-text {
        font-size: 0.6rem;
    }

    .course-card-icon {
        font-size: 0.8rem;
        width: 1.2rem;
    }

    .course-card-detail-text {
        font-size: 0.6rem;
    }

    .course-card-see-details-button {
        font-size: 0.6rem;
    }
}

@media (width < 450px) {
    .course-card-container {
        height:20rem;
    }
}
