.AboutHome {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    text-align: justify ;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.4s ease;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: var(--accent-color);
}

.header{
    transform: translateX(-20px);
    opacity: 0;
    transition: transform 0.6s ease, opacity 0.6s ease;
    margin-bottom: 10px;
}

.paragraph {
    transform: translateX(-20px);
    opacity: 0;
    transition: transform 0.9s ease, opacity 0.9s ease;
    padding-left:20px;
    padding-right:20px;
    font-weight: 400;
    text-align: center;
    width:90%
}

.learn-more {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.4s ease;
    border-radius: 5px;
    transform: translateX(-40px);
    opacity: 0;
    transition: transform 2s ease, opacity 2s ease;

    &:hover {
        background-color: var(--accent-color);
    }
}

.scrolled {
    & .image {
        opacity: 0.5;
    }

    & .overlay {
        opacity: 1;
    }

    & .header,
    & .paragraph,
    & .learn-more {
        transform: translateX(0);
        opacity: 1;
    }
}
