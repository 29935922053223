.course-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
    
  .course-modal-overlay { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
    
  .course-modal-content {
    position: absolute;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 0.5rem;
    z-index: 20;
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  .course-modal-time-label{
    position: absolute;
    top: 1.5rem;
    left:1.5rem;
  }
  .course-modal-content-left{
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: start;
  }
  .course-modal-photo{
      width: 100%;
      height: 26rem;
      object-fit: cover;
      border-radius: 0.5rem;
  }
  .course-modal-content-right{
      text-align: left;
      width: 69%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      min-height: 26rem;
  }
  .course-modal-content-right-top{
  width: 100%;
  }
  .course-modal-content-right-bottom{
    height: 100%;
  }
  .course-modal-lectures{
    width:100%
  }
  .course-modal-lectures-list{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: max-content;
    overflow: scroll;
    gap: 0.5rem;
  }
  .course-modal-lectures-list::-webkit-scrollbar{
    display: none;
  }
  .course-modal-lecture{
    height: 100%;
  }
  .course-modal-details{
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;
  }
  .course-modal-detail-section{
      margin-top: 0.4rem;
      display: flex;
      align-items: center;
      justify-content:left ;
      gap:0.3rem
  }
  .course-modal-icon{
      font-size: 1.3rem;
      width: 1.5rem;
      align-items: center;
  }
  .attend-course-button{
    padding-bottom: 1rem;
  }
  .attend-course-link{
    padding: 0.9rem;
  }
  @media(width<1135px){
    .course-modal-content-right{
      width: 59%;
    }
    .course-modal-content-left{
      width: 39%;
    }
  }
  @media(width<920px){
    .course-modal-content{
      flex-direction: column;
      padding: 0.8rem;
    }
    .course-modal-photo{
      height: 15rem;
    }
    .course-modal-content-right{
      width: 100%;
    }
    .course-modal-content-left{
      width: 100%;
    }
  }
  @media(width<400px){
    .course-modal-photo{
      height: 10rem;
    }
    .course-modal-title{
      font-size: 1.5rem;
    }
    .course-modal-content-right{
      min-height: 22rem;
    }
  }