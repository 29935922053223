@import "../node_modules/normalize.css/normalize.css";
@import '~@fortawesome/fontawesome-free/css/all.min.css';

:root {
    --primary-color:#0085ca;
    --accent-color:#002f6c;
}

* {
    margin: 0;
    padding: 0;
}

h1 { margin: 0; }

.App {
    text-align: center;
    font-family: "Space Grotesk", monospace;
}

.primary-button {
    font-size: 1rem;
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: #0085ca;
    color: #ffffff;
    border:none;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
        background-color: #002f6c;
        cursor: pointer;
    }
}

.display-none {
    display: none !important;
}

body {
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
