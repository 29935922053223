.whats-next-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whats-next {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whats-next-header {
    font-size: 4rem;
    margin: 1rem 0;
}

.whats-next-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
}

.whats-next-event {
    width: 33.3333%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.whats-next-see-more-button {
    width: 7rem;
    height: min-content;
    margin: 1rem
}


@media (width < 950px) {
    .whats-next-header {
        font-size: 3rem;
    }

    .whats-next-event {
        width: 50%;
    }
}

@media (width < 520px) {
    .whats-next-header {
        font-size: 2.5rem;
    }

    .whats-next-events {
        width: 90%;
    }

    .whats-next-event {
        width: 100%;
    }
}
