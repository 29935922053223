.label {
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    font-weight: 500;
}

@media (width < 550px){
    .label{
        font-size: 0.5rem;
    }
}
