.event-calendar-container {
    width: 90%;
    padding-top: 6rem;
    display: flex;
    align-items: start;
    height: 35rem;
}

.rbc-calendar {
    width: 60%;
}

.rbc-month-view {
    border-radius: 0.5rem;
    overflow: hidden;
}

.rbc-header {
    background-color: var(--primary-color);
    color: #ffffff;
    font-weight: 400;
}

.rbc-btn-group {
    border-radius: 0.5rem;
}

.rbc-toolbar-btn-group {
    border-radius: 0.5rem;
}

.rbc-toolbar button {
    background-color: var(--primary-color);
    color: #fff;
    transition: all 0.3s ease;

    &:hover, &.rbc-active:hover {
        background-color: var(--accent-color);
        color: #fff;
    }

    &.rbc-active:focus, &:active:focus {
        background-color: #f3f3f3;
        color: var(--primary-color);
        box-shadow: inset 0 0 0 1px #ccc;
    } 

    &:active, &.rbc-active {
        background-color: var(--primary-color);
    }
} 

.calendar-selected-event-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
}

.calendar-event-not-selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
}

.rbc-event--past {
    opacity: 0.6
}

.rbc-event--event {
    background-color: #ffcd00 !important;
    color: #000;
}

.rbc-event--course {
    background-color: #71c5e8 !important;
    color: #000;
}

.rbc-selected.rbc-event {
    box-shadow: inset 0 0 0 2px #000;
    background-color: unset;
}


@media (width<1000px) {
    .event-calendar-container {
        flex-direction: column;
        height: 70rem;
        align-items: center;
    }

    .rbc-calendar {
        width: 100%;
    }

    .calendar-selected-event-details {
        padding-top: 1rem;
        width: 80%;
    }
}

@media (width < 800px) {
    .event-calendar-container {
        height: 60rem;
        font-size: 0.8rem;
    }

    .rbc-calendar {
        width: 100%;
    }

    .rbc-toolbar {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;

        & button {
            font-size: 0.8rem;
        }
    }

    .rbc-toolbar-label {
        flex-grow: initial !important;
    }
}

@media (width < 650px) {
    .event-calendar-container {
        font-size: 0.6rem;
        height: 50rem;
    }

    .rbc-toolbar button {
        font-size: 0.6rem;
        padding: 0.3rem 0.5rem;
    }
    
    .rbc-toolbar-label {
        font-size: 0.7rem;
    }
}

@media (width < 420px) {
    .event-calendar-container {
        font-size: 0.45rem;
        height: 45rem;
    }

    .rbc-toolbar button {
        font-size: 0.5rem;
        padding: 0.2rem 0.4rem;
    }

    .rbc-toolbar-label {
        font-size: 0.5rem;
    }
}
