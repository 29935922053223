.contact-page {
    padding: 12vh 5vw 10vh 5vw;
    text-align: center;

    & h1 {
        font-size: 36px;
        margin-bottom: 5vh;
        color: #000;
    }
}
/*Tried to keep all the pages with a same padding ratio */

.contact-form {
    max-width: 50vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & label {
        margin-bottom: 1.2vh;
        font-weight: bold;
    }

    & input,
    & textarea {
        width: 100%;
        padding: 1.4vh;
        border: 1px solid #b7b7b7;
        border-radius: 6px;
        box-sizing: border-box;
        display: block;
        visibility: visible;
    }

    & textarea {
        resize: vertical; 
        min-height: 17vh; 
    }
}

button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: var(--primary-color); 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: var(--accent-color); /* Darker colour on hover*/
    }
}


@media (max-width: 768px) {
    .contact-form {
        max-width: 65vw;
    }

}
